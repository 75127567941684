import { render, staticRenderFns } from "./MvRow.vue?vue&type=template&id=40f2110c&scoped=true&"
import script from "./MvRow.vue?vue&type=script&lang=js&"
export * from "./MvRow.vue?vue&type=script&lang=js&"
import style0 from "./MvRow.vue?vue&type=style&index=0&id=40f2110c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40f2110c",
  null
  
)

export default component.exports